import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
// import Features from "../components/Features"; // a list of features
// import BlogRoll from "../components/BlogRoll"; // a few recent blogs
import ProjectRoll from "../components/ProjectRoll"; // latest projects
import LandingHeroImage from "../components/LandingHeroImage"; // Hero Image Zone
// import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

// eslint-disable-next-line
export const IndexPageTemplate = ({
	hero,
	projects,
	showBlog,
	// about,
	// reviews,
}) => {
	const heroImage = getImage(hero.image) || hero.image
	// console.log('hero:', hero)

	return (
		<div>
			{/* Hero */}
			{hero.showHero && (
				<LandingHeroImage
					height={'50vh'}
					imgPosition='center center'
					img={heroImage}
					title={hero.title}
					subtitle={hero.subtitle}
					extraClasses='landingHero'
					cta_btn_text={hero.cta_btn_text}
					cta_btn_link={hero.cta_btn_link}
				/>
			)}

			{/* Projects */}
			{projects.enabled && (
				<section className='section'>
					<div className='container'>
						<div className='columns'>
							<div className='column is-10 is-offset-1'>
								<div className='content'>
									<h3 className='has-text-weight-semibold is-size-2 has-text-dark mb-5'>{projects.heading}</h3>
									<p className='mb-4 is-size-5 has-text-grey'>{projects.description}</p>
								</div>
							</div>
						</div>

						<div className='columns'>
							<div className='column is-10 is-offset-1'>
								<ProjectRoll />

								<div className='column is-12 has-text-centered mt-5'>
									<Link className='button is-primary is-outlined' to='/projects'>
										{projects.cta_btn_text}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	)
}

IndexPageTemplate.propTypes = {
  // image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  // title: PropTypes.string,
  // heading: PropTypes.string,
  // subheading: PropTypes.string,
  // mainpitch: PropTypes.object,
  // description: PropTypes.string,
  intro: PropTypes.shape({
    features: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
		<Layout transparentNavbar={true}>
			<IndexPageTemplate
				hero={frontmatter.hero}
				projects={frontmatter.projects}
				showBlog={frontmatter.showBlog}
				// image={frontmatter.image}
				// title={frontmatter.title}
				// subtitle={frontmatter.subtitle}
				// intro={frontmatter.intro}
				// about={frontmatter.about}
				// reviews={frontmatter.reviews}
			/>
		</Layout>
	)
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				showBlog
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				hero {
					title
					subtitle
					cta_btn_text
					cta_btn_link
					showHero
					image {
						childImageSharp {
							gatsbyImageData(quality: 100, layout: FULL_WIDTH)
						}
					}
				}
				projects {
					enabled
					heading
					description
					cta_btn_text
				}
				# intro {
				# 	enabled
				# 	heading
				# 	centered
				# 	description
				# 	features {
				# 		image {
				# 			childImageSharp {
				# 				gatsbyImageData(width: 300, quality: 64, layout: CONSTRAINED)
				# 			}
				# 		}
				# 		text
				# 		description
				# 	}
				# }
				# about {
				# 	enabled
				# 	heading
				# 	description
				# 	cta_btn_text
				# 	cta_btn_link
				# 	image {
				# 		childImageSharp {
				# 			gatsbyImageData(width: 1000, quality: 100, layout: CONSTRAINED)
				# 		}
				# 	}
				# }
				# reviews {
				# 	enabled
				# 	heading
				# 	description
				# 	reviews {
				# 		name
				# 		quote
				# 		image {
				# 			childImageSharp {
				# 				gatsbyImageData(width: 240, quality: 100, layout: CONSTRAINED)
				# 			}
				# 		}
				# 	}
				# }
			}
		}
	}
`
